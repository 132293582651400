<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import productImg1 from "@/page/tcamvay/components/img/4_1.png";
import productImg2 from "@/page/wisementpresta/components/img/4_2.png";

export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      text:`Mobile sẽ thu thập dữ liệu cá nhân sau ("Dữ liệu" hoặc "Dữ liệu cá nhân") từ bạn ("Chủ sở hữu" hoặc "bạn") với tư cách là một trong những Khách hàng của chúng tôi:

      Thông tin liên hệ (số điện thoại di động, email, địa chỉ cá nhân);
      Dữ liệu nhận dạng (tên, ngày sinh, chụp ảnh, nhận dạng chính thức, giới tính được chỉ định);
      Dữ liệu sinh trắc học;
      Dữ liệu vị trí và định vị địa lý (vị trí gần đúng, vị trí chính xác, múi giờ);
      dữ liệu việc làm và giáo dục;
      dữ liệu truyền thông;
      Dữ liệu từ điện thoại hoặc thiết bị di động của bạn (thông tin phần cứng, IMEI, địa chỉ IP, địa chỉ MAC, GPS ADID hoặc nhận dạng thiết bị khác, nhà cung 
      cấp dịch vụ di động);
      Dữ liệu sử dụng thiết bị của bạn (danh sách các ứng dụng đã cài đặt, dữ liệu tài khoản);
      Dữ liệu SMS (dấu thời gian, số điện thoại và nội dung SMS);
      Danh sách liên hệ, tin nhắn văn bản bạn gửi, lịch sử điện thoại và tần suất cuộc gọi của bạn;
      Dữ liệu tài sản và tài chính (dữ liệu hoặc tài liệu về thu nhập hoặc việc làm, trình độ học vấn, mục đích hoặc mục đích của khoản vay);
      Dữ liệu bạn cung cấp trong đơn xin vay tiền của mình;
      Dữ liệu giao dịch về các giao dịch của bạn với chúng tôi hoặc thông qua chúng tôi và các giao dịch của bạn với các tổ chức chuyển tiền, tổ chức tài trợ 
      thanh toán điện tử, tổ chức huy động vốn từ cộng đồng, tổ chức tín dụng và các tổ chức tài chính hoặc tổ chức thương mại điện tử khác;
      Dữ liệu sử dụng ứng dụng từ các ứng dụng khác (chi tiết cài đặt, lưu lượng truy cập, điều hướng).

Chúng tôi có sử dụng dữ liệu cá nhân nhạy cảm không?

   Không có yêu cầu trực tiếp về dữ liệu liên quan đến nguồn gốc chủng tộc hoặc dân tộc, sức khỏe, niềm tin triết học hoặc tôn giáo, thành viên công đoàn, 
   quan điểm chính trị, khuynh hướng tình dục (khác với giới tính được chỉ định của bạn, được yêu cầu để ngăn chặn mối lo ngại về rửa tiền). Tuy nhiên, chúng 
   tôi thu thập thông tin liên quan đến danh sách liên hệ, tin nhắn văn bản, nhật ký cuộc gọi, nhật ký tin nhắn văn bản và hình ảnh của bạn. Những thông tin 
   này có thể chứa dữ liệu cá nhân nhạy cảm.

Làm cách nào để chúng tôi thu thập và cập nhật dữ liệu của bạn?

Dữ liệu cá nhân sẽ được thu thập và cập nhật theo những cách sau:

      Khi bạn tải xuống một ứng dụng từ chúng tôi và/hoặc yêu cầu một khoản vay từ chúng tôi;
      Các tương tác khác của bạn với chúng tôi, bao gồm dữ liệu cá nhân mà bạn tự nguyện chia sẻ với bộ phận dịch vụ khách hàng hoặc nhân viên và đại lý 
      của chúng tôi;
      Điện thoại hoặc thiết bị di động của bạn khi bạn cấp quyền truy cập và các quyền như mô tả bên dưới;
      văn phòng tín dụng hoặc cơ quan phòng chống gian lận;
      Cookie, đèn hiệu web và các phương tiện thu thập dữ liệu tự động từ xa khác;
      Khi cần thiết, với sự đồng ý của bạn, chuyển tiền cho các tổ chức quỹ thanh toán điện tử, tổ chức huy động vốn từ cộng đồng, tổ chức tín dụng và các tổ 
      chức tài chính và tổ chức thương mại điện tử khác; Tôi
      Các bên thứ ba và các nguồn công khai khác khi cần thiết và được sự đồng ý của bạn.

Chúng tôi sử dụng dữ liệu của bạn để làm gì?

Các mục đích chính cần thiết để tạo, duy trì và thực hiện mối quan hệ pháp lý giữa bạn (sau đây gọi là “mục đích chính”) như sau:

      Xác minh danh tính của bạn, ngăn chặn gian lận và tuân thủ nghĩa vụ “biết khách hàng của bạn” theo luật chống rửa tiền hiện hành;
      Tạo hồ sơ tín dụng của bạn và chỉ định cho bạn điểm tín dụng để xác định khả năng đủ điều kiện của bạn;
      Để xác định và hiểu bằng cách thu thập dữ liệu cá nhân và tích hợp các tệp về bạn sẽ được duy trì trong cơ sở hạ tầng kỹ thuật và hệ thống thông tin cũng 
      như các tệp khác;
      Kết hợp dữ liệu cá nhân vào các công cụ pháp lý, tuân thủ các hợp đồng mà bạn đã ký kết, bao gồm nhưng không giới hạn ở một số hợp đồng người dùng 
      nhất định đối với các ứng dụng và nền tảng của chúng tôi cũng như hợp đồng cho vay hoặc tín dụng giữa các chủ sở hữu, đồng thời quản lý và quản lý các 
      hợp đồng nói trên;
      Khởi tạo, quản lý, phục vụ, duy trì và theo đuổi khoản nợ của chủ sở hữu;
      Để cung cấp dịch vụ cho bạn với tư cách là khách hàng, quản lý tài khoản của bạn và cung cấp hỗ trợ cho bạn;
      Gửi cho bạn thông tin giao dịch hoặc thông báo giao dịch, lời nhắc và thông báo thanh toán qua dịch vụ điện thoại tự động hoặc tin nhắn, tin nhắn văn bản 
      hoặc email được ghi âm trước;
      Chuyển nhượng hoặc chuyển nhượng dưới bất kỳ hình thức nào các quyền và/hoặc nghĩa vụ phát sinh từ hợp đồng trên cho bên thứ ba;
      sử dụng dữ liệu cá nhân trong bối cảnh của bất kỳ loại hành động hoặc thủ tục thu thập tư pháp hoặc ngoài tư pháp nào;
      Tuân thủ tất cả các luật, quy định hiện hành và quy tắc chung;
      Điều tra các thay đổi đối với chi tiết giao dịch của bạn;
      Ngăn ngừa sự giả dối;
      Lấy dữ liệu cá nhân để cải thiện mô hình tín dụng, mô hình dữ liệu và các dịch vụ khác;
      Phân tích hành vi của khách hàng và duy trì chất lượng dịch vụ của chúng tôi.

Các mục đích phụ không cần thiết cho việc duy trì và thực hiện mối quan hệ pháp lý giữa bạn (sau đây gọi là "Mục đích phụ") như sau:

      Tiến hành khảo sát để cải thiện dịch vụ của chúng tôi;
      Sử dụng hình ảnh và lời chứng thực mà bạn tự nguyện cung cấp cho chúng tôi cho mục đích quảng cáo; và
      Tiếp thị, quảng cáo và/hoặc khuyến mãi các sản phẩm và/hoặc dịch vụ do Chủ sở hữu cung cấp thông qua bất kỳ phương tiện vật chất và/hoặc điện tử 
      nào. Sự đồng ý của bạn với tuyên bố về quyền riêng tư này cấu thành sự đồng ý rõ ràng của bạn để chúng tôi có thể liên hệ với bạn để cung cấp cho bạn 
      các dịch vụ tài chính.

Mục đích phụ cần có sự đồng ý của bạn. Nếu bạn không đồng ý cho phép dữ liệu liên hệ của bạn được sử dụng cho các mục đích phụ này, vui lòng gửi email. Chúng tôi sẽ đợi năm ngày làm việc trước khi sử dụng dữ liệu cá nhân của bạn cho mục đích phụ.
Bạn có thể truy cập những quyền gì trên thiết bị của mình?

Tùy thuộc vào hệ điều hành Android hoặc iOS và phiên bản ứng dụng được cài đặt trên thiết bị của bạn, ứng dụng có thể truy cập các quyền thiết bị sau. 
Luôn cập nhật ứng dụng của bạn để bạn có thể sử dụng các tính năng mới nhất, an toàn nhất.

      Đọc danh bạ của bạn - sẽ lấy thông tin về danh sách liên lạc của bạn (tên liên lạc và số điện thoại, tần suất liên lạc, dấu thời gian). sử dụng thông tin này trong mô hình tín dụng và bảo lãnh của họ để xác định xem bạn có đủ điều kiện hay không. Chúng tôi sử dụng quy trình xử lý tự động để tìm hiểu về các mối quan hệ trực tuyến của bạn, điều này cũng giúp các mô hình lừa đảo của chúng tôi xác minh danh tính của bạn. Chúng tôi sẽ không bao giờ liên hệ với bất kỳ người liên hệ nào của bạn hoặc cung cấp bất kỳ thông tin cá nhân nào của bạn cho những người liên hệ của họ trừ khi bạn thông báo riêng cho chúng tôi một cách rõ ràng.
      Tìm tài khoản trên thiết bị của bạn - Kiểm tra danh sách tài khoản người dùng được tạo trên thiết bị của bạn và kiểm tra địa chỉ email được sử dụng để tạo từng tài khoản. Điều này giúp các mô hình lừa đảo của chúng tôi xác minh danh tính của bạn. Nó cũng sử dụng thông tin này trong các mô hình tín dụng và bảo lãnh để xác định khả năng hội đủ điều kiện của bạn.
      Đọc tin nhắn văn bản của bạn - sẽ truy xuất thông tin về tin nhắn văn bản được lưu trên thiết bị của bạn (dấu thời gian, số điện thoại và nội dung tin nhắn văn bản). sử dụng thông tin này trong các mô hình tín dụng và bảo lãnh để xác định khả năng hội đủ điều kiện của bạn đối với các dịch vụ của chúng tôi. 
      Chúng tôi sử dụng quy trình xử lý tự động để hiểu hoạt động tài chính của bạn, điều này cũng giúp các mô hình gian lận của chúng tôi xác minh danh tính của bạn. Nội dung tin nhắn văn bản của bạn sẽ không bao giờ được chia sẻ.
      Nhận SMS - Được sử dụng để tự động xác nhận mật khẩu một lần (OTP) được gửi tới thiết bị của bạn qua SMS.
      Máy ảnh - Bạn sẽ được yêu cầu tải lên ảnh giấy tờ tùy thân của mình. Nó cũng có thể yêu cầu bạn tải lên các bản sao tài liệu để chứng minh thu nhập của bạn.
      Album Ảnh - Bạn có thể được yêu cầu cho phép chúng tôi kiểm tra dữ liệu thông tin lưu trữ album ảnh của bạn (số lượng ảnh, bộ nhớ ảnh, ngày tạo ảnh). Hình ảnh chụp ảnh của bạn sẽ không bao giờ được truy cập và chúng sẽ không bao giờ được chia sẻ.
      Đọc, sửa đổi hoặc xóa nội dung trên thẻ SD của bạn - Bạn cũng có thể được yêu cầu tải lên ảnh các tài liệu để chứng minh thu nhập của mình.
 Đọc trạng thái và danh tính điện thoại - Khi bạn đăng ký tài khoản, nó sẽ tự động lấy số điện thoại từ thiết bị của bạn. Điều này đảm bảo rằng số điện thoại di động hợp lệ, chính xác và được liên kết với thiết bị bạn đã sử dụng để mở tài khoản của mình.
      Truy xuất các ứng dụng đang chạy - sẽ truy xuất danh sách các ứng dụng được cài đặt trên thiết bị của bạn. sử dụng thông tin này trong mô hình tín dụng 
      và bảo lãnh của họ để xác định xem bạn có đủ điều kiện hay không.
      Vị trí - Điều này giúp các mô hình lừa đảo của chúng tôi xác minh danh tính của bạn. Nó cũng sử dụng thông tin này trong các mô hình tín dụng và bảo lãnh để xác định khả năng hội đủ điều kiện của bạn. Chúng tôi cũng sử dụng dữ liệu vị trí cho mục đích nghiên cứu.
      Cần có quyền này để chạy dịch vụ giao diện người dùng - ứng dụng tải ảnh lên.
      Chạy khi khởi động - Điều này cho phép ứng dụng gửi thông báo đến thiết bị của bạn khi bạn khởi động lại thiết bị.
      Xem và thay đổi kết nối mạng - Được sử dụng để thông báo cho ứng dụng khi kết nối mạng thay đổi để chúng tôi có thể xác định xem bạn có kết nối với Internet hay không.
      Xem kết nối Wi-Fi - Sử dụng địa chỉ IP và loại mạng của thiết bị của bạn để phát hiện và ngăn chặn gian lận.
      Nhận dữ liệu từ Internet - Bạn cần có quyền này để gửi yêu cầu thông qua ứng dụng và cho phép ứng dụng truy cập Internet.
      Ngăn điện thoại chuyển sang chế độ ngủ - Quyền này là bắt buộc đối với một số tính năng và dịch vụ nhất định trong ứng dụng, chẳng hạn như tin nhắn 
      do chính ứng dụng gửi.

Chúng tôi chia sẻ dữ liệu của bạn với ai?

Dữ liệu cá nhân sẽ được chuyển giao trong nước và quốc tế cho các cá nhân hoặc tổ chức thuộc các loại, danh mục hoặc lĩnh vực sau cho các mục đích sau:

A.Để xác định và thu thập kiến thức thông qua việc thu thập Dữ liệu cá nhân của Chủ sở hữu và tích hợp Dữ liệu cá nhân vào cơ sở hạ tầng kỹ thuật, hệ thống thông tin và các tệp khác; i.i. Kết hợp Dữ liệu cá nhân của Chủ sở hữu vào các công cụ pháp lý và tuân thủ các quy định của Chủ sở hữu và Chủ sở hữu nghĩa vụ theo hợp đồng được ký kết giữa chúng ta (bao gồm nhưng không giới hạn ở một số hợp đồng người dùng nhất định đối với các ứng dụng và nền tảng của chúng tôi cũng như hợp đồng cho vay hoặc hợp đồng tín dụng). Quản lý và điều hành các hợp đồng nêu trên; 3. Chuyển nhượng hoặc chuyển nhượng dưới 
bất kỳ hình thức nào các quyền và/hoặc nghĩa vụ phát sinh từ các hợp đồng nêu trên cho bên thứ ba; v. Tuân thủ tất cả các luật, quy định hiện hành và các 
quy định chung; Khám phá và đánh giá dữ liệu cá nhân để tạo và chỉ định xếp hạng tín dụng hoặc xếp hạng tương tự cho chủ sở hữu. 7. Thu thập thông tin để cải thiện mô hình tín dụng, dữ liệu và dịch vụ của chúng tôi; Điều tra các thay đổi trong thông tin giao dịch của bạn; Khởi tạo, quản lý, phục vụ, duy trì và theo đuổi khoản nợ của chủ sở hữu.

B. Tiết lộ cho cơ quan có thẩm quyền của tiểu bang và nước ngoài để tuân thủ mọi luật, quy định hiện hành hoặc yêu cầu pháp lý và để ngăn ngừa tổn hại.

C. Bên thứ ba có được danh mục tín dụng hoặc cung cấp tài chính cho bên thứ ba để có được nguồn tài chính hoặc nguồn lực.

D. Cho các bên thứ ba quan tâm đến việc cung cấp tài chính hoặc mua lại cổ phần hoặc tài sản hoặc sáp nhập với các cổ đông hoặc công ty con của công ty 
ở Mexico hoặc trên toàn thế giới.

E. Cùng với các đối tác kinh doanh của chúng tôi, nhằm mục đích tìm kiếm thương mại và các dịch vụ liên quan được cung cấp cũng như cung cấp cho bạn quyền truy cập vào các dịch vụ của bên thứ ba trong Ứng dụng.

Ngoài ra, theo pháp luật hiện hành, đôi khi cần phải truyền thông tin tín dụng của chủ sở hữu đến các công ty thông tin tín dụng hoặc văn phòng tín dụng để tuân thủ các mục đích chính được nêu ở đây và theo nghĩa này, chấp nhận tuyên bố về quyền riêng tư này (sau đây được gọi là "Tuyên bố") và bằng cách 
đồng ý ký kết Thỏa thuận người dùng, bạn xác nhận rằng chúng tôi có thể chuyển thông tin của bạn cho các công ty thông tin tín dụng hoặc văn phòng tín dụng nêu trên, kể cả liên quan đến việc không tuân thủ hợp đồng được ký kết giữa hai bên. Chủ sở hữu và người dùng.

Chúng tôi cũng chia sẻ dữ liệu cá nhân của bạn với các nhà cung cấp phần mềm liên quan đến giải ngân, chuyển tiền, dịch vụ thanh toán, nhà cung cấp bảo 
mật thông tin, công ty bảo hiểm, cơ quan thu nợ, cố vấn kế toán và pháp lý, dịch vụ phân tích, chuyển giao và lưu trữ dữ liệu và các dịch vụ khác. Các nhà 
cung cấp xử lý dữ liệu cá nhân của bạn thay mặt.
Đồng ý với các điều khoản chuyển nhượng

Bạn thực hiện những bước nào để hạn chế việc sử dụng và tiết lộ dữ liệu cá nhân?

   Danh sách loại trừ đã được tạo cho những người không muốn nhận quảng cáo từ . Nếu bạn nhận được một quảng cáo từ đó và không còn muốn nhận nó 
   nữa, bạn có thể gửi email có chứa tên đầy đủ của bạn yêu cầu xóa bạn khỏi danh sách phân phối mà thông tin hoặc quảng cáo đã được gửi đến. Trong cùng một email, bạn có thể yêu cầu thêm thông tin về danh sách này.

Thay đổi đối với Tuyên bố về quyền riêng tư

Thông báo này hiện đã được đăng trên trang Internet để bạn xem xét. Tương tự như vậy, mọi thay đổi đối với Thông báo này vào bất kỳ lúc nào cũng sẽ được đăng tại địa chỉ trên.`,

      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F7F7FF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3950px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin-top: -70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 40px;
 margin-left: 490px;
 color: #FF591C;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 89px;
  margin-right: 89px;
  font-size: 16px;
  color: #666666;
}
</style>
